export default [
  {
    title: 'Valid?',
    dataIndex: 'valid',
    slots: {
      customRender: 'tags',
    },
  },
  {
    title: 'Kode Customer *',
    dataIndex: 'code',
  },
  {
    title: 'Nama Customer *',
    dataIndex: 'owner',
  },
  {
    title: 'Nama Toko *',
    dataIndex: 'name',
  },
  {
    title: 'Alamat *',
    dataIndex: 'address',
  },
  {
    title: 'Kode Wilayah',
    dataIndex: 'wilayah_id',
  },
  {
    title: 'Wilayah',
    dataIndex: 'wilayah',
  },
  {
    title: 'Nomor Telepon *',
    dataIndex: 'phone',
  },
  {
    title: 'Nomor Telepon 2',
    dataIndex: 'phone2',
  },
  {
    title: 'Latitude',
    dataIndex: 'lat',
  },
  {
    title: 'Longitude',
    dataIndex: 'lng',
  },
  {
    title: 'Cluster *',
    dataIndex: 'cluster_id',
  },
  {
    title: 'SSM *',
    dataIndex: 'nopeg_ssm',
  },
  {
    title: 'ASM *',
    dataIndex: 'nopeg_sm',
  },
  {
    title: 'TSO *',
    dataIndex: 'nopeg_am',
  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
]
